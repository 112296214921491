// imported fonts
@import url('https://fonts.googleapis.com/css2?family=Spline+Sans&family=Spline+Sans+Mono:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');



$bg-color: #F9844A;

html,
body,
#root {
  height: 100%;
  // background: #bc4e9c;  /* fallback for old browsers */
  // background: -webkit-linear-gradient(to right, #f80759, #bc4e9c);  /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(to right, #f80759, #bc4e9c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}


// for index
body {
  margin: 0;
  font-family: "Mona Sans", "Mona Sans Fallback", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-feature-settings: "ss02" on, "ss01" on;
  font-weight: 700 !important;
  letter-spacing: -0.01em !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // background: #00509D;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// for app 
.App {
  // text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $bg-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.arrow {
  $size: 30px;
  $width: 2px;

  margin: 0px 130px 0px 130px;

  cursor: pointer;
  display: block;
  width: calc($size / 1.414);
  height: calc($size / 1.414 * 2);
  position: relative;

  & span,
  &:before,
  &:after {
    background: #fff;
    content: '';
    display: block;
    width: $size;
    height: $width;
    position: absolute;
    top: calc(50% - (#{$width}/ 2));
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }

  & span {
    width: 0;
  }

  &:hover span {
    width: calc($size * 1.414);
  }

  @each $direction in 'left' 'right' {
    &.arrow--#{$direction} {

      & span,
      &:before,
      &:after {
        #{$direction}: 0;
        transform-origin: #{$direction} 50%;
      }

      &:before,
      &:after {
        transition: #{$direction} 0.3s 0.05s;
      }

      & span {
        transition: width 0.3s,
        #{$direction} 0.3s 0.05s;
      }

      &:hover {

        & span,
        &:before,
        &:after {
          #{$direction}: calc($size / 1.414 * -1);
        }
      }
    }
  }
}

// fonts

.cust-font {
  color: #ffffff;
  font-weight: 400;
  text-shadow: 0px 2px 1px rgb(100 0 0 / 80%);
  letter-spacing: 2px;
}